import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './img/logo2.png';
import emailjs from 'emailjs-com';
import $ from 'jquery';
import 'bootstrap/js/dist/modal.js';
import { projectFirestore } from './firebase'
import { useEffect, useState } from 'react';

function App(props) {

  const addPreInscription = async (Object) => {
    projectFirestore.collection('i_preinscription').doc().set(Object);
  }

  const initialFieldValue = {
    sejour: '',
    premier_sejour: 'OUI',
    nom: '',
    prenom: '',
    sexe: 'M',
    brithdate: '',
    tel_mere: '',
    tel_pere: '',
    email_mere: '',
    email_pere: '',
    moyen_de_paiement: 'CB',
    changement_sejour: 'OUI',
    commentaire: '',
    archived: false,
    createdTime: new Date(),
    lastUpdated: new Date(),
  }

  var [values, setValues] = useState(initialFieldValue);

  useEffect(() => {
    setValues({ ...initialFieldValue })
    // eslint-disable-next-line
  }, [props.currentId]);

  const handleInputChange = e => {
    var { name, value } = e.target
    setValues({
      ...values,
      [name]: value,
    })
  }

  const redirect = () => {
    window.location.href = 'https://yaniv.fr'
  }

  function sendEmail(e) {
    e.preventDefault();

    addPreInscription(values);

    if (projectFirestore.collection('i_preinscription').doc('parameters').get('send_mail')) {
      emailjs.sendForm('main', 'yaniv_preinscription', e.target, 'user_Y3UQsYad53aPihrsRp7v0')
        .then((result) => {
          $('#exampleModal').modal('show')
        }, (error) => {
          console.log(error.text);
        });
    } else {
          console.log('pasenvoyer');
          $('#exampleModal').modal('show')
    }
  }

  return (
    <>
      {/* <BackgroundSlider
        images={[img2, img3]}
        duration={10} transition={2} /> */}
      <div className="split left">
        <img src={logo} className="logo" alt="logo yaniv" />
        <div className="centered">
          <h1>YANIV HIVER 2020 : pré-inscriptions des séjours</h1>
          <p>En raison de la situation actuelle, nous avons mis en place un système de pré-inscription en ligne. Merci de remplir le formulaire suivant. YANIV se chargera de vous rappeler afin de confirmer votre inscription et de valider avec vous le paiement de l’acompte.</p>
          <p>IMPORTANT : il ne s’agit pas d’une inscription. Le remplissage de ce formulaire ne garantit pas votre place dans un séjour. Seule l’acceptation par YANIV et le paiement de l’acompte garantissent votre place dans nos séjours. </p>
          <p>Merci pour votre compréhension.</p>
          <p>{'>>'}</p>
        </div>
      </div>
      <div className="split right ">
        <div className="centered">
          <form className="contact-form" onSubmit={sendEmail}>
            <h1>Pré-inscriptions pour les séjours :</h1>
            <br />
            <div className="row ">
              <div className="col-md form-group">
                <label htmlFor="sejour">Sejour :</label>
                <select className="form-control" id="sejour" name="sejour" onChange={handleInputChange}>
                  <option value="selectionner" defaultChecked defaultValue hidden>...</option>
                  <option value="YANIV AUSSOIS (Du 7 au 14 février) ">AUSSOIS (Du 7 au 14 février)</option>
                  <option value="YANIV AUSSOIS (Du 14 au 21 février)">AUSSOIS (Du 14 au 21 février)</option>
                  <option value="YANIV NDB SESSION 1">N.D.B. (Du 14 au 21 février)</option>
                  <option value="YANIV NDB SESSION 2">N.D.B. (Du 21 au 28 février)</option>
                  <option value="YANIV CHATEL">CHATEL (Du 21 au 28 février)</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="premier_sejour">Premier Sejour à YANIV</label>
                <select className="form-control" id="premier_sejour" name="premier_sejour" onChange={handleInputChange}>
                  <option value="OUI">OUI</option>
                  <option value="NON">NON</option>
                </select>
              </div>
              <div className="col-md form-group">
                <label htmlFor="sexe">Sexe :</label>
                <select className="form-control" id="sexe" name="sexe">
                  <option value="M">M</option>
                  <option value="F">F</option>
                </select>
              </div>

            </div>

            <div className="row">
              <div className="col-md form-group">
                <label htmlFor="nom">Nom :</label>
                <input type="text" className="form-control" id="nom" name="nom" placeholder="Nom de Famille" onChange={handleInputChange} required />
              </div>
              <div className="col-md form-group">
                <label htmlFor="prenom">Prenom :</label>
                <input type="text" className="form-control" id="prenom" name="prenom" placeholder="Prenom" onChange={handleInputChange} required />
              </div>
              <div className="col-md form-group">
                <label htmlFor="brithdate">Date de Naissance :</label>
                <input type="date" className="form-control" id="brithdate" name="brithdate" placeholder="Date de Naissance" onChange={handleInputChange} required />
              </div>
            </div>
            <div className="row">
              <div className="col-md form-group">
                <label htmlFor="tel_mere">Téléphone portable (mère) :</label>
                <input type="phone" className="form-control" id="tel_mere" name="tel_mere" placeholder="Téléphone portable (mère)" onChange={handleInputChange} required />
              </div>
              <div className="col-md form-group">
                <label htmlFor="email_mere">Adresse Email (mère) :</label>
                <input type="email" className="form-control" id="email_mere" name="email_mere" placeholder="Adresse Email (mère)" onChange={handleInputChange} required />
              </div>
            </div>
            <div className="row">
              <div className="col-md form-group">
                <label htmlFor="tel_mere">Téléphone portable (père) :</label>
                <input type="phone" className="form-control" id="tel_pere" name="tel_pere" placeholder="Téléphone portable (père)" onChange={handleInputChange} required />
              </div>
              <div className="col-md form-group">
                <label htmlFor="email_pere">Adresse Email (père) :</label>
                <input type="email" className="form-control" id="email_pere" name="email_pere" placeholder="Adresse Email (père)" onChange={handleInputChange} required />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="moyen_de_paiement">Mode paiement de l'acompte</label>
              <br />
              <small>Le bureau de YANIV vous appellera dans les 48h pour verser l'acompte de 450€</small>
              <select className="form-control" id="moyen_de_paiement" name="moyen_de_paiement" onChange={handleInputChange}>
                <option value="CB">CB</option>
                <option value="Chèque">Chèque</option>
                <option value="Autre">Autre</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="changement_sejour">En cas d'annulation, j'accepte que YANIV me bascule sur un autre séjour.</label>
              <br />
              <small>Pour garantir votre place à YANIV, si le séjour que vous avez sélectionné venait à être annulé, nous basculerons votre inscription sur un autre séjour recommandé. Vous serez informé de cette décision au préalable afin de confirmer.</small>
              <select className="form-control" id="changement_sejour" name="changement_sejour" onChange={handleInputChange}>
                <option value="OUI">OUI</option>
                <option value="NON">NON</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="commentaire">Commentaire ?</label>
              <textarea className="form-control" id="commentaire" rows="3" name="commentaire" onChange={handleInputChange}></textarea>
            </div>
            <br />
            <div className="row">
              <div className="col-md"></div>
              <button type="submit" className="btn btn-outline-light col-md-3 " >Valider</button>
              <div className="col-md-1"></div>
            </div>
          </form>

        </div>
      </div>
      <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Demande Envoyée !</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Le bureau de YANIV vous appellera dans les 48h afin de confirmer votre inscription.
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={redirect}>Fermer</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;