import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/storage';

const settings = {timestampsInSnapshots: true};
var firebaseConfig = {
    apiKey: "AIzaSyAnYkWkpAxDkBGR_goEtAU1TZTrLQdxU_8",
    authDomain: "yanivwebapp.firebaseapp.com",
    databaseURL: "https://yanivwebapp.firebaseio.com",
    projectId: "yanivwebapp",
    // storageBucket: "yanivwebapp.appspot.com",
    storageBucket: "lesactati",
    messagingSenderId: "467164897662",
    appId: "1:467164897662:web:7eedc70c982fcdde9b932f"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.firestore().settings(settings);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
export default firebase.firestore();